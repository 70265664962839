<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.new_m", {
                                m: $t("message.company_y"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                  <el-form
                      ref="form"
                      status-icon
                      :model="form"
                      :rules="rules"
                      enctype="multipart/form-data" 
                      method="post">
                      
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.name") }}
                      </span>
                        <el-form-item
                            prop="name"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.name"
                                v-model="form.name"
                                :placeholder="$t('message.name')"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("message.parent_company_id") }}
                          </span>
                          <el-form-item>
                            <select-company
                              :id="form.parent_company_id"
                              :size="'medium'"
                              v-model="form.parent_company_id"
                              className="w-100"
                            >
                            </select-company>
                          </el-form-item>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.director") }}
                      </span>
                        <el-form-item
                            prop="director"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.director"
                                v-model="form.director"
                                :placeholder="$t('message.director')"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.country") }}
                      </span>
                        <el-form-item
                            prop="country"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.country"
                                v-model="form.country"
                                :placeholder="$t('message.country')"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.region") }}
                        </span>
                        <el-form-item
                            prop="city"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.city"
                                v-model="form.city"
                                :placeholder="$t('message.region')"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.address") }}
                      </span>
                        <el-form-item
                            prop="address"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.address"
                                v-model="form.address"
                                :placeholder="$t('message.address')"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.web_site") }}
                      </span>
                        <el-form-item
                            prop="web_site"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.web_site"
                                v-model="form.web_site"
                                :placeholder="$t('message.web_site')"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.email") }}
                      </span>
                        <el-form-item
                            prop="email"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.email"
                                v-model="form.email"
                                :placeholder="$t('message.email')"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></crm-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{ $t("message.phone_number") }}
                            </span>
                            <el-form-item prop="phone_number">
                              <crm-input
                                :type="'tel'"
                                :maskFormat="'tel'"
                                :placeholder="$t('message.phone_number')"
                                :inputValue="form.phone_number"
                                v-model="form.phone_number"
                                :size="'medium'"
                                :class="mode ? 'input__day' : 'input__night'"
                              ></crm-input>
                            </el-form-item>
                          </div>
                      </el-col>
                    
                      <el-col :span="21" class="company_info" >
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                          {{ $t("message.description") }}
                      </span>
                        <el-form-item
                            prop="description"
                            class="label_mini">
                            <el-input
                                type="textarea"
                                :rows="2"
                                :placeholder="$t('message.description')"
                                :inputValue="form.description"
                                v-model="form.description"
                                class="text-area-el"
                                :class="mode ? 'input__day' : 'input__night'"
                            ></el-input>
                        </el-form-item>
                      </el-col> 
                      <el-col :span="3" >
                        <div id="app" class="my-upload">
                           <span class="input--label d-block mb-3" :class="mode ? 'text__day2' : 'text__night2'">
                              {{ $t("message.logo") }}
                            </span>
                          <el-upload
                            class="upload-demo"
                            action="/"
                            :limit="1"
                            :on-change="createImageList"
                            :file-list="imageList"
                            list-type="picture-card"
                            :auto-upload="false"
                            :class="mode ? '' : 'upload__mynight'"
                          >
                            <i class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                    </el-col>
                                
                    </el-row>
                  </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import axios from 'axios';

export default {
    mixins: [form, drawer,show],
    name: "CompanyController",
    data() {
        return {
          imageList: [],
          image: "",
          baseUrl: process.env.VUE_APP_URL.substring(
            0,
            process.env.VUE_APP_URL.length - 3
          ),
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "company/rules",
            model: "company/model",
            columns: "company/columns",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            save: "company/update",
            //  empty: "company/empty",
              show: "company/show",
        }),
         fetchData() {
            if (!this.loadingData) {
              this.loadingData = true;
              this.show(this.selectedItem.id)
                .then((res) => {
                  this.form = JSON.parse(JSON.stringify(res.data.result.data.company));
                  if (this.form.image) {
                    this.imageList.push({
                      name: this.form.image,
                      url: this.baseUrl + this.form.image,
                    });
                  }
                  this.loadingData = false;
                })
                .catch((err) => {
                  this.loadingData = false;
                });
            }
          },
        afterOpen() {
          this.fetchData();
        },
         createImageList(file) {
            this.form.image = file.raw;
          },
       onFileChange(e) {
          var files = e.target.files || e.dataTransfer.files;
          if (!files.length) return;
          this.createImage(files[0]);
        },
        createImage(file) {
          var image = new Image();
          var reader = new FileReader();
          var vm = this;

          reader.onload = (e) => {
            vm.image = e.target.result;
          };
          reader.readAsDataURL(file);
        },
        removeImage: function (e) {
          this.image = "";
    },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    let  formData = new FormData();
                    for (const key in this.form) {
                      formData.append(key, this.form[key]);
                    }
                    formData["id"] = this.form.id;
                    this.save(formData)
                        .then((res) => {
                            this.fileList = [];
                            this.imageList = [];
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
<style  lang="scss">
.company_info .el-textarea__inner{
  min-height: 150px !important;
}
.my-upload .el-upload-list {
    position: relative;
    z-index: 2;
}
.my-upload .el-upload.el-upload--picture-card {
    position: absolute;
    left: 0px !important;
    z-index: 1;
    width: 91%;
    line-height: 150px;
    vertical-align: top;
    
}
.my-upload .upload-demo {
    position: relative;
}

.upload__mynight{
  .el-upload.el-upload--picture-card{
    background-color: transparent;
  }
}

</style>
